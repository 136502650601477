// Header.js
import React from 'react';
import './Header.css'; // Create a CSS file for styling

function Header() {
  return (
    <header className="header">
      <a href="/" >
      <div className="logo" ></div>
      </a>
      <nav className="navbar">
        <ul>
        <a href="/" >
          <li>Home</li>
        </a>
          <li>About</li>
          <li>Contact</li>
        </ul> 
      </nav>
    </header>
  );
}

export default Header;
