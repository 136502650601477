import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import HomeLayout from './HomeLayout'; // Import the HomeLayout component
import CourseDescriptionLayout from './CourseDescriptionLayout'; // Import the CourseDescriptionLayout component

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>

          {/* Default route for the homepage */}
          <Route index element={<HomeLayout />} />
         
          {/* Route for the offer page */}
          <Route path="/offer" element={<CourseDescriptionLayout />} />

          {/* Route for the 404 page */}
          <Route path="*" element={<h1>404: Not Found</h1>} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
