// Hero.js
import React, { useEffect, useState } from 'react';
import './Hero.css'; // Create a CSS file for styling

function Hero() {
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    // Delay the appearance of text and buttons
    const timeout = setTimeout(() => {
      setIsShown(true);
    }, 500); // Adjust the delay duration as needed

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <section className={`hero ${isShown ? 'shown' : ''}`}>
      <div className="hero-content">
        <h1>Hay otra forma de aprender musica</h1>
        <p>Estudiar lo que amamos no tiene por que ser dificil</p>
        <a href="/offer">
        <button className={`cta-button-1 ${isShown ? 'shown' : ''}`}>Comienza aquí</button>
        </a>
      </div>
      
    </section>
  );
}

export default Hero;
