import React, { useState } from 'react';
import './CourseDescription2.css';

function CourseDescription() {
  const [expandedChapter, setExpandedChapter] = useState(null);

  const toggleChapter = (chapter) => {
    setExpandedChapter((prevState) => (prevState === chapter ? null : chapter));
  };

  const chapters = [
    {
      title: "Duraciones",
      content: "Sonido, pulso y acento, métrica y células rítmicas."
    },
    {
      title: "Alturas",
      content: "Notas musicales, intervalos, escala y melodía."
    },
    {
      title: "Armonía",
      content: "Consonancia y disonancia, acordes, tonalidad y conducción de voces."
    },
    {
      title: "Forma",
      content: "Cambio y permanencia, textura, funciones formales y análisis."
    },
  ];

  return (
    <div className="course-description">
      <section className="hero-offer">
        <div className="hero-content-offer">
          <h1>Sesiones de Música Express</h1>
          <p>• 100% online: cursa a tu ritmo</p>
          <p>• Contenidos pensados para vos, nos concentramos en lo importante </p>
          <p>• Clases de consulta en vivo</p>
          <a href="https://mpago.la/1oen6cE">
            <button>Inscribirme ahora</button>
          </a>
        </div>
      </section>

      <section className="course-curriculum-container">
        <div className="course-details">
          <div className="course-image-container">
            <img src="course-logo.png" alt="Sobre el curso" className="course-details-image" />
          </div>
          <div class="separator"></div>
          <h3 className="course-details-header">Sesiones de Musica Express</h3>
          <div className="course-details-content">
            <div className="detail free">
              <img src="price-tag.png" alt="" className="detail-icon" />60% OFF hasta Navidad
            </div>
            <div className="detail lessons">
              <img src="clock.png" alt="" className="detail-icon" />20 clases
            </div>
            <div className="detail live lessons">
              <img src="calendar.png" alt="" className="detail-icon" />5 sesiones en directo
            </div>
            <div className="detail video-content">
              <img src="music-note.png" alt="" className="detail-icon" />Actividades
            </div>
          </div>
        </div>
        <div className="course-curriculum">
          <h2 className="curriculum-header">Contenidos del curso</h2>

          {chapters.map((chapter, index) => (
            <div key={index} className="curriculum-chapter">
              <div className="chapter-header" onClick={() => toggleChapter(chapter.title)}>
                <span className="chapter-title">{chapter.title}</span>
                <span className={`chapter-toggle ${expandedChapter === chapter.title ? 'flipped' : ''}`}>&#9660;</span>
              </div>
              {expandedChapter === chapter.title && (
                <div className="chapter-content">{chapter.content}</div>
              )}
            </div>
          ))}
        <a href="programa.pdf" target="_blank">
          <p> Ver programa de estudio completo</p>
        </a>

        <a href="https://mpago.la/1oen6cE">
            <button>Inscribirme ahora</button>
          </a>

        </div>
      </section>

      <section className="one-card">
        <div className="payment-options">
          <div className="title-with-icons">
            <h2>Formas de pago</h2>
            <p>Paga con tarjeta de crédito, débito, en efectivo o por transferencia bancaria.</p>
            <div className="icons-container">
              <img src="mercadopago (1).png" alt="Mercado Pago" className="payment-icon1" />
              <img src="visa (1).png" alt="Visa" className="payment-icon2" />
              <img src="mastercard (1).png" alt="Mastercard" className="payment-icon3" />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default CourseDescription;
