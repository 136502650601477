import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <p>&copy; 2023 El Hormiguero</p>
        <ul>
          <li>
            <a href="https://www.instagram.com/hormiguero_musica" target="_blank" rel="noopener noreferrer">
              <img src='instagram.png' alt='Instagram'/>
            </a>
          </li>
          <li>
            <a href="https://www.facebook.com/hormiguero.musica" target="_blank" rel="noopener noreferrer">
              <img src='facebook.png' alt='Facebook'/>
            </a>
          </li>

          {/* Add more icons here as needed */}
        </ul>
      </div>
    </footer>
  );
}

export default Footer;
