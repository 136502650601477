// HomeLayout.js
import React from 'react';
import Header from './Header';
import Hero from './Hero';
import CourseSection from './CourseSection';
import Footer from './Footer';
import TrabajaConNosotros from './TrabajaConNosotros';

function HomeLayout() {
  return (
    <div>
      <Header />
      <Hero />
      <CourseSection />
      <TrabajaConNosotros />
      <Footer />
    </div>
  );
}

export default HomeLayout;
