// CourseSection.js
import React, { useEffect, useState } from 'react';
import './CourseSection.css';

function CourseSection() {
  const [isShaking, setIsShaking] = useState(false);

  useEffect(() => {
    const shakeInterval = setInterval(() => {
      setIsShaking(true);

      // Reset shaking after a short delay
      setTimeout(() => {
        setIsShaking(false);
      }, 500); // Adjust the duration of the shake effect as needed
    }, 5000); // Adjust the interval (n seconds) for shaking as needed

    return () => {
      clearInterval(shakeInterval);
    };
  }, []);

  return (
    <section className='course-section'>
      {/* Left Extra Card */}
      <div className='coming-soon-card left-card'>
        <div className="course-card">
          {/* Content similar to the course card */}
          <img src="course-image2.jpg" alt="Course" className="course-image" />
          <div className="course-content">
            <h2>Mentorias 1:1</h2>
            <p className="course-description">
              Sesiones personalizadas con expertos. Elige tu mentor y comienza a aprender.
            </p>
              <button className="cta-button-2">Muy pronto</button>
          </div>
        </div>
      </div>

      {/* Course Card */}
      <div className="course-card">
        <img src="course-image.jpg" alt="Course" className="course-image" />
        <div className="course-content">
          <h2>Sesiones de Musica Express</h2>
          <p className="course-description">
            Tu carrera como musico profesional comienza aqui. Conoce todo lo que necesitas para convertirte en un verdadero artista.
          </p>
          <a target="_blank" rel="noreferrer" href="https://form.typeform.com/to/DiRnnluD">
            <button className={`cta-button-2 ${isShaking ? 'shaking' : ''}`}>Prueba una clase</button>
          </a>
        </div>
      </div>

      {/* Right Extra Card */}
      <div className='coming-soon-card right-card'>
        <div className="course-card">
          {/* Content similar to the course card */}
          <img src="course-image3.jpg" alt="Course" className="course-image" />
          <div className="course-content">
            <h2>Sesiones de Soporte</h2>
            <p className="course-description">
              Te acompañamos en tu trayecto academico. Resuelve tus dudas y prepara tus examenes.
            </p>
              <button className="cta-button-2">Muy pronto</button>
          </div>
        </div>
      </div>

      {/* Floating Buttons */}
      <div className="floating-buttons-right">
        <a href="https://wa.me/+5493517338775">
          <img src="whatsapp.png" alt="WhatsApp" className="whatsapp-button" />
        </a>
      </div>
    </section>
  );
}

export default CourseSection;
