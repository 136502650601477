import React from 'react';
import './TrabajaConNosotros.css';

function TrabajaConNosotros() {
  return (
    <div className="trabaja-con-nosotros-container">
      <h2>Únete a Nuestro Equipo</h2>
      <p>¿Estás interesado en trabajar con nosotros? ¡Nos encantaría saber de ti!</p>
      <a href="https://forms.gle/ZprnxruYGxNraSYe9" target="_blank" rel="noopener noreferrer">
        <button className="apply-button">Aplica Ahora</button>
      </a>
    </div>
  );
}

export default TrabajaConNosotros;
