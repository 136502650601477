// LoginLayout.js
import React from 'react';
import Header from './Header';
import CourseDescription from './CourseDescription2';
import Footer from './Footer';

function CourseLayout() {
  return (
    <div>
      <Header />
      <CourseDescription />
      <Footer />
    </div>
  );
}

export default CourseLayout;
